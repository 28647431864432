import { errorValues } from "eth-rpc-errors/dist/error-constants";
import { BAD_REQUEST_ERROR_STATUS_CODE, Theme } from "./constants";
import { toast as ReactToast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Cookies from "js-cookie";
import { ThemeMapper } from "@/indexer/hooks/useIndexingToast";

interface IMetamaskActionError {
  [code: string]: {
    standard: string;
    message: string;
  };
}

export const DEFAULT_NETWORK_ERROR_MESSAGE =
  "We are experiencing an issue loading your request - our engineering team has been notified.";

export const DEFAULT_METAMASK_ERROR_MESSAGE =
  "There was an issue processing your transaction - our engineering team has been notified.";

export const METAMASK_ACTION_ERROR_CODES: IMetamaskActionError = {
  ...errorValues,
  "-32002": {
    standard: errorValues["-32002"]?.standard,
    message: "Metamask is locked, please open the extension before connecting",
  },
  4001: {
    standard: errorValues["4001"]?.standard,
    message: "You rejected the request in your wallet",
  },
};

export const CONTRACT_ERROR_CODES: { [error: string]: string } = {
  ACTION_REJECTED: "You rejected the request in your wallet",
};

export const readNetworkError = async (error: any) => {
  const isBadRequest =
    error?.response?.status === BAD_REQUEST_ERROR_STATUS_CODE;

  if (isBadRequest) {
    return error?.response?.data?.message;
  }

  return DEFAULT_NETWORK_ERROR_MESSAGE;
};

export const metamaskErrorToast = (
  error: { code: number; reason: string; error: any },
  disableDefault = false,
) => {
  if (error["error"]?.code === -32603) return;

  if (!METAMASK_ACTION_ERROR_CODES[error.code]?.message && disableDefault)
    return;

  const errorMessage =
    METAMASK_ACTION_ERROR_CODES[error.code]?.message ||
    (typeof CONTRACT_ERROR_CODES[error.code] === "object"
      ? CONTRACT_ERROR_CODES[error.code]
      : CONTRACT_ERROR_CODES[error.code]) ||
    DEFAULT_METAMASK_ERROR_MESSAGE;

  const theme = Cookies.get("theme") || Theme.Light;

  ReactToast.error(errorMessage, {
    theme: ThemeMapper[theme],
    position: "bottom-right",
    draggable: false,
    transition: Slide,
  });
};
