import { Blockchain } from "./types";

const getPaymentMethodsForBlockchain = (blockchain: Blockchain) => {
  const { [`NEXT_PUBLIC_${blockchain}_ERC20_TOKENS`]: tokens } =
    PROCESS_CHAIN_ENV;

  return tokens!.split(",").reduce((paymentMethods: any[], token: string) => {
    const {
      [`NEXT_PUBLIC_${blockchain}_${token}_TOKEN_ID`]: id,
      [`NEXT_PUBLIC_${blockchain}_${token}_TOKEN_ADDRESS`]: tokenAddress,
      [`NEXT_PUBLIC_${blockchain}_${token}_TOKEN_LABEL`]: label,
      [`NEXT_PUBLIC_${blockchain}_${token}_TOKEN_SYMBOL`]: symbol,
      [`NEXT_PUBLIC_${blockchain}_${token}_TOKEN_FEE`]: fee,
      [`NEXT_PUBLIC_${blockchain}_${token}_TOKEN_DECIMALS`]: decimals,
      [`NEXT_PUBLIC_${blockchain}_${token}_TOKEN_ACTIVITY`]: activity,
    } = PROCESS_CHAIN_ENV;

    return {
      ...paymentMethods,
      [tokenAddress!.toLowerCase()]: {
        id,
        label,
        tokenAddress: tokenAddress!.toLowerCase(),
        symbol,
        fee: +fee!,
        decimals: +decimals!,
        activity: JSON.parse(activity!),
      },
    };
  }, {});
};

const getAllBlockchainConfigs = () => {
  const chains = process.env.NEXT_PUBLIC_CHAINS!;

  const blockchains = chains
    .split(",")
    .map((blockchain) => blockchain as Blockchain);

  return blockchains.reduce((blockchainConfigs, blockchain) => {
    const {
      [`NEXT_PUBLIC_${blockchain}_CHAIN_ID`]: id,
      [`NEXT_PUBLIC_${blockchain}_CHAIN_NAME`]: name,
      [`NEXT_PUBLIC_${blockchain}_CHAIN_LABEL`]: label,
      [`NEXT_PUBLIC_${blockchain}_ETHERSCAN_URL`]: etherscanUrl,
      [`NEXT_PUBLIC_${blockchain}_RPC_URL`]: rpcUrl,
      [`NEXT_PUBLIC_${blockchain}_NFT_CONTRACT_FACTORY`]: nftFactoryAddress,
      [`NEXT_PUBLIC_${blockchain}_ART_ORDER`]: artOrderAddress,
      [`NEXT_PUBLIC_${blockchain}_ENDEMIC_EXCHANGE`]: exchangeAddress,
      [`NEXT_PUBLIC_${blockchain}_ROYALTIES_PROVIDER`]:
        royaltiesProviderAddress,
      [`NEXT_PUBLIC_${blockchain}_PAYMENT_MANAGER`]: paymentManagerAddress,
      [`NEXT_PUBLIC_${blockchain}_ENDEMIC_COLLECTION`]: endemicCollection,
      [`NEXT_PUBLIC_${blockchain}_LEGACY_COLLECTION`]: legacyCollection,
      [`NEXT_PUBLIC_${blockchain}_TOKEN_LOCKING`]: tokenLockingAddress,
      [`NEXT_PUBLIC_${blockchain}_END_TOKEN`]: endToken,
    } = PROCESS_CHAIN_ENV;

    return {
      ...blockchainConfigs,
      [blockchain]: {
        id: +id,
        name,
        label,
        etherscanUrl,
        rpcUrl,
        contracts: {
          nftFactoryAddress,
          exchangeAddress,
          royaltiesProviderAddress,
          paymentManagerAddress,
          artOrderAddress,
          tokenLockingAddress,
          endToken,
        },
        paymentMethods: getPaymentMethodsForBlockchain(blockchain),
        endemicCollection,
        legacyCollection,
      },
    };
  }, {});
};

export { getAllBlockchainConfigs };
