import { useRef, useCallback, useEffect } from "react";
import { toast, Theme as ToastifyTheme } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Theme } from "@/shared/constants";
import Cookies from "js-cookie";

interface IThemeMapper {
  [endemicTheme: string]: ToastifyTheme;
}

export const ThemeMapper: IThemeMapper = {
  light: "dark",
  night: "light",
  endemic: "light",
};

export default function useIndexingToast(isIndexing: boolean) {
  const toastRef = useRef<any>(null);

  const theme = Cookies.get("theme") || Theme.Light;

  useEffect(() => {
    if (!isIndexing) {
      toast.dismiss(toastRef.current);
    }
  }, [isIndexing]);

  return useCallback(() => {
    toastRef.current = toast.info(
      "Your transaction was successfully submitted and it's being indexed.",
      {
        theme: ThemeMapper[theme],
        position: "bottom-center",
        autoClose: false,
        icon: false,
        draggable: false,
      },
    );
  }, [theme]);
}
