"use client";
import config from "@shared/config/client";
import { Connector } from "./types";
import { metamaskErrorToast } from "@/shared/errors";

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

const redirectToStoreOrExtension = () => {
  if (isMobile()) {
    const deepLink = `${window.location.hostname}/login`;

    if (/Android/i.test(navigator.userAgent)) {
      // For Android, intent filters with custom URL schemes
      window.location.href = `intent://${deepLink}#Intent;scheme=https;package=io.metamask;end`;
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // let timeout = 0;
      // const handleMetamaskNotInstalled = () => {
      //   clearTimeout(timeout);
      //   window.location.href =
      //     "https://apps.apple.com/app/metamask/id1438144202";
      // };

      const { href, protocol } = window.location;
      const originLink = href.replace(protocol, "").slice(2);
      const link = `https://metamask.app.link/dapp/${originLink}`;
      const dappLink = `dapp://${originLink}`;
      const userAgent = window?.navigator?.userAgent || "";
      if (/\bAndroid(?:.+)Mobile\b/i.test(userAgent)) {
        window.location.href = dappLink;
      } else {
        window.open(link, "_blank");
      }
      // timeout = setTimeout(
      //   handleMetamaskNotInstalled,
      //   500,
      // ) as unknown as number;
    }
  } else if (/Chrome/i.test(navigator.userAgent)) {
    window.open(
      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
      "_blank",
    );
  } else if (/Firefox/i.test(navigator.userAgent)) {
    window.open(
      "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/",
      "_blank",
    );
  } else {
    // Default to a general MetaMask download page
    window.open(config?.site.metamask_url, "_blank");
  }
};

const getProvider = () => {
  let provider = window.ethereum;
  if (window.ethereum?.providers?.length) {
    window.ethereum.providers.forEach(async (p: any) => {
      if (p.isMetaMask) provider = p;
    });
  }

  if (!provider) {
    redirectToStoreOrExtension();
    return;
  }

  return provider;
};

const isAuthorized = async () => {
  const provider = getProvider();
  const accounts = await provider.request({
    method: "eth_accounts",
  });
  return !!accounts[0];
};

const connect = async () => {
  const provider = getProvider();

  try {
    await provider.request({ method: "eth_requestAccounts" });
    return provider;
  } catch (error: any) {
    metamaskErrorToast(error);
    throw new Error("User Rejected");
  }
};

const connector: Connector = {
  id: "metamask",
  connect,
  isAuthorized,
};

export default connector;
