"use client";
import WalletLink from "walletlink";
import config from "@shared/config/client";
import { Connector } from "./types";
import { Blockchain } from "@/shared/config/blockchain/types";

const chooseProvider = (network: Blockchain | null) => {
  let provider;
  if (window.ethereum?.providers?.length) {
    window.ethereum.providers.forEach(async (p: any) => {
      if (p.isCoinbaseWallet) {
        provider = p;
      }
    });
  }

  if (!provider) {
    const wl = new WalletLink({
      appName: config.coinbaseAppName,
    });
    const chainConfig = network && config.chains[network];

    provider = wl.makeWeb3Provider(`${chainConfig!.rpcUrl}`, +chainConfig!.id);
  }

  return provider;
};

const connect = async (network: Blockchain | null) => {
  const provider = chooseProvider(network);

  try {
    await provider.request({ method: "eth_requestAccounts" });
    return provider;
  } catch (error) {
    throw new Error("User Rejected");
  }
};

const connector: Connector = {
  id: "coinbase",
  connect,
};

export default connector;
