"use client";
import config from "@shared/config/client";
import { Connector } from "./types";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { Blockchain } from "@/shared/config/blockchain/types";
import LogoIcon from "@/assets/icons/themed/light/endy.svg?url";

const connect = async (network: Blockchain | null) => {
  const chainConfig = network && config.chains[network];
  if (chainConfig) {
    const provider = await EthereumProvider.init({
      projectId: config.walletConnectProjectId,
      chains: [+chainConfig.id],
      rpcMap: {
        [chainConfig.id]: chainConfig.rpcUrl,
      },
      showQrModal: true,

      methods: [
        "eth_getBalance",
        "eth_sendTransaction",
        "eth_signTypedData",
        "eth_signTypedData_v4",
        "eth_requestAccounts",
        "personal_sign",
        "eth_sign",
      ],
      events: ["connect", "chainChanged", "accountsChanged", "disconnect"],
      metadata: {
        name: config.site.title,
        description: config.site.description,
        url: config.url,
        icons: [LogoIcon.src],
      },
    });
    try {
      await provider.enable();

      if (provider.request && typeof provider.request === "function") {
        await provider.request({ method: "eth_requestAccounts" });
      }

      return provider;
    } catch {
      throw new Error("User rejected");
    }
  }
  throw Error("No chain id");
};

const connector: Connector = {
  id: "walletconnect",
  connect,
};
export default connector;
